<template>
    <section id="contact">
        <div class="container">
            <h2>
                 <strong>اتصل بنا <br> <span class="subheading"></span></strong> <!-- Translated title -->
            </h2>
            <div class="contact-content">
                <div class="contact-form-section">
                    <form @submit.prevent="submitForm" class="contact-form">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <input type="text" style="margin-top: 22px" class="contactinput" id="name" v-model="form.name" required placeholder="اسمك" aria-required="true" />
                                </div>
                                <div class="form-group">
                                    <input type="text" style="margin-top: 24px" class="contactinput" id="brand" v-model="form.brand" required placeholder="اسم العلامة التجارية" aria-required="true" />
                                </div>
                                <div class="form-group">
                                    <input type="tel" style="margin-top: 24px" class="contactinput" id="phone" v-model="form.phone" required placeholder="رقم هاتفك" aria-required="true" />
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <textarea id="message" style="margin-top: 22px" class="contactinput" v-model="form.message" required placeholder="رسالتك" rows="7" aria-required="true"></textarea>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="submit-btn">أرسل رسالة</button> <!-- Translated button text -->
                    </form>
                </div>
                <div class="contact-info-section">
                    <p>نحن نحب أن نسمع منك! لا تتردد في التواصل معنا لأي استفسارات أو تعليقات.</p> <!-- Translated paragraph -->
                    <div class="social-links">
                        <a href="#" aria-label="Facebook" class="social-icon"><i class="fab fa-facebook-f"></i></a>
                        <a href="#" aria-label="Twitter" class="social-icon"><i class="fab fa-twitter"></i></a>
                        <a href="#" aria-label="LinkedIn" class="social-icon"><i class="fab fa-linkedin-in"></i></a>
                        <a href="#" aria-label="Instagram" class="social-icon"><i class="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ContactComponent',
    mounted() {
        const script = document.createElement('script');
        script.src = "https://smtpjs.com/v3/smtp.js";
        script.async = true;
        document.head.appendChild(script);
    },
    data() {
        return {
            form: {
                name: '',
                brand: '',
                phone: '',
                message: ''
            }
        };
    },
    methods: {
        submitForm() {
            // const { name, brand, phone, message } = this.form;
            // window.Email.send({
            //     Host: "smtp.zoho.com",
            //     Username: "it@hesabtech.com",
            //     Password: "a",
            //     To: 'ahsatroy2009@gmail.com',
            //     From: "eslam.kataty@gmail.com",
            //     Subject: `New message from ${name} - ${brand}`,
            //     Body: `
            //         <strong>Name:</strong> ${name}<br>
            //         <strong>Brand:</strong> ${brand}<br>
            //         <strong>Phone:</strong> ${phone}<br>
            //         <strong>Message:</strong> ${message}
            //     `
            // })
            // .then((message) => alert("Email sent successfully: " + message))
            // .catch((error) => alert("Failed to send email: " + error));
        }
    }
};
</script>
<style scoped>
/* General section styling */
#contact {
    padding: 60px 20px;
    background-color: #fff;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Heading styling */
h2 {
    text-align: right; /* Align text to the right for RTL */
    margin-bottom: 40px;
    font-size: 2rem;
    color: #333;
}

h2 strong {
    font-weight: bold;
    color: #233651;
}

h2 .subheading {
    display: block;
    font-size: 1.2rem;
    color: #555;
    margin-top: 10px;
    /* Adjust margin for RTL */
    margin-right: 60px;
}

/* Layout styling */
.contact-content {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    direction: rtl; /* Set direction to RTL */
}

.contact-form-section,
.contact-info-section {
    flex: 1;
}

.contact-form-section {
    max-width: 600px;
}

.contact-info-section {
    max-width: 400px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

label {
    font-weight: bold;
    margin-bottom: 8px;
    color: #555;
}

.contactinput {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1rem;
    line-height: 1.5;
    text-align: right; /* Align text to the right for RTL */
}

.contactinput:focus {
    border-color: #233651;
    outline: none;
}

.contactinput::placeholder {
    font-size: 0.875rem; /* Smaller font size for the placeholder */
    text-align: right; /* Align placeholder text to the right for RTL */
}

textarea {
    resize: vertical;
    min-height: 150px;
}

/* Button styling */
.submit-btn {
    background-color: #233651;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: #41546e;
}

/* Social links styling */
.social-links {
    display: flex;
    gap: 15px; /* Adjusted gap for better spacing */
    margin-bottom: 20px;
}

.social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px; /* Circle diameter */
    height: 50px; /* Circle diameter */
    border-radius: 50%; /* Makes the element circular */
    background-color: #f8f9fa; /* Background color of the circle */
    color: #233651; /* Icon color */
    font-size: 1.5rem;
    border: 1px solid #233651; /* Border color and width */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    text-decoration: none; /* Removes underline from links */
}

.social-icon:hover {
    background-color: #41546e; /* Background color on hover */
    color: #fff; /* Icon color on hover */
    border-color: #233651; /* Border color on hover */
}

/* Responsive design */
@media (max-width: 768px) {
    .contact-content {
        flex-direction: column;
    }

    .contact-form-section,
    .contact-info-section {
        max-width: 100%;
    }

    .submit-btn {
        padding: 12px;
    }

    .contactinput {
        margin-top: 10px !important;
    }
}

#contact form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#contact form input, #contact form textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
</style>
