import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./Axios";
import i18n from "./plugins/i18n";
import VueSweetalert2 from "vue-sweetalert2";
import VueCookies from "vue-cookies";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "normalize.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueHtmlToPaper from "vue-html-to-paper";
import "./assets/css/style.css";
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
};
// import 'swiper/swiper-bundle.css'; // Import Swiper styles globally
import { register } from 'swiper/element/bundle';


register();

createApp(App)
  .use(VueHtmlToPaper, options)
  .use(VueHtmlToPaper)
  .use("v-select", vSelect)
  .use(i18n)
  .use(router)
  .use(VueSweetalert2)
  .use(VueCookies)
  .mount("#app");


