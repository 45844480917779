<template>
    <div id="app">
      <HeaderComponent />
      <HeroSliderComponent />
      <ServicesComponent />
      <aboutcomponent />
      <PartnersComponent />
      <ContactComponent />
      <FooterComponent />
    </div>
  </template>

  <script>
  import HeaderComponent from './components/HeaderComponent.vue';
  import HeroSliderComponent from './components/HeroSliderComponent.vue';
  import ServicesComponent from './components/ServicesComponent.vue';
  import aboutcomponent from './components/about-component.vue';
  import PartnersComponent from './components/PartnersComponent.vue';
  import ContactComponent from './components/ContactComponent.vue';
  import FooterComponent from './components/FooterComponent.vue';

  export default {
    name: 'App',
    components: {
      HeaderComponent,
      HeroSliderComponent,
      ServicesComponent,
      aboutcomponent,
      PartnersComponent,
      ContactComponent,
      FooterComponent
    }
  };
  </script>

  <style>
  /* Import Almarai font from Google Fonts */
  @import url('https://fonts.googleapis.com/css2?family=Almarai:wght@400;700&display=swap');

  html, body {
    font-family: 'Almarai', sans-serif; /* Apply Almarai font */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    direction: rtl; /* Set the direction to RTL */
  }

  /* Adjusting margin or padding for RTL layout */
  @media (max-width: 992px) {
    .container {
      width: 100% !important;
      text-align: right; /* Align text to the right */
    }
  }

  .some-class {
    margin-right: 20px; /* Replacing margin-left with margin-right */
    /* Other RTL adjustments */
  }
  </style>
