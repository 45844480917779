<template>
    <footer>
      <div class="container">
        <div class="row" style="    margin-right: auto;">
          <div class="col-md-3 mt-15">
            <h3>روابط سريعة</h3>
            <ul class="footer-links">
              <li><a href="#services">خدماتنا</a></li>
              <li><a href="#about">معلومات عنا</a></li>
              <li><a href="#partners">شركاؤنا</a></li>
              <li><a href="#contact">تواصل معنا</a></li>
            </ul>
          </div>
          <div class="col-md-3 mt-15">
            <h3>مساعدة</h3>
            <ul class="footer-links">
              <li><a href="#">الشروط والأحكام</a></li>
              <li><a href="#">الخصوصية والسياسة</a></li>
              <li><a href="#">الأسئلة الشائعة</a></li>
            </ul>
          </div>
          <div class="col-md-3 mt-15">
            <h3>جهات الاتصال</h3>
            <ul class="footer-links">
              <li><a href="#">058153996</a></li>
              <li><a href="#">info@hesabtech.com</a></li>
            </ul>
          </div>
          <div class="col-md-3 mt-15">
            <h3>اشترك</h3>
            <p class="subscribe-class">اشترك معنا للحصول على النشرات الإخبارية</p>
            <form @submit.prevent="submitSubscription">
              <input type="email" v-model="email" class="form-control" placeholder="عنوان البريد الإلكتروني" required>
              <button type="submit">إرسال</button>
            </form>
          </div>
        </div>
      </div>
    </footer>
  </template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
      email: ''
    };
  },
  methods: {
    submitSubscription() {
      // Handle the subscription logic here
      console.log('Email submitted:', this.email);
    }
  }
};
</script>
<style scoped>
input[type="email"] {
  padding: 0px;
  flex: 1;
  margin-left: 1px; /* Adjusted for RTL */
  min-width: 200px;
}

button[type="submit"] {
  padding: 5px 10px;
  background-color: #233651;
  color: white;
  border: none;
  cursor: pointer;
}

form {
  display: flex;
  gap: 0px;
}

footer {
  background: url('../assets/img/about-bg.png') no-repeat;
  background-position: calc(100% + 50px) top;
  background-size: contain;
  color: #233651;
  padding: 20px 0;
  direction: rtl; /* Added for RTL support */
}

footer .container {
  display: flex;
  justify-content: space-between;
}

footer p {
  margin: 0;
}

footer ul {
  list-style: none;
  padding: 0;
}

footer ul li {
  margin: 5px 0;
}

footer ul li a {
  color: #233651;
  text-decoration: none;
}

.footer-links {
  margin-top: 30px;
  color: #a7a7a7;
  font-size: 14px;
}

.footer-links li a,
.subscribe-class {
  margin-top: 30px;
  color: #a7a7a7;
  font-size: 14px;
  min-width: 250px;
  padding-bottom: 20px;
}

input[type="email"]::placeholder {
  padding-right: 5px; /* Adjusted for RTL */
  color: #888;
}
</style>
