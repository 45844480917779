<template>
    <header>
      <div class="container" dir="rtl">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand logo" href="#">
            <img class="logo" src="../assets/img/logo.png" alt="شعار حساب تك">
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#phone_navbar"
            aria-controls="phone_navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="phone_navbar">
            <ul class="mb-3 navbar-nav me-xl-3 navigation gap-xl-3 d-flex align-items-center mb-lg-0">
              <li class="nav-item"><a class="nav-link" href="#services">خدماتنا</a></li>
              <li class="nav-item"><a class="nav-link" href="#about">من نحن</a></li>
              <li class="nav-item"><a class="nav-link" href="#partners">شركاؤنا</a></li>
              <li class="nav-item"><a class="nav-link" href="#contact">اتصل بنا</a></li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  </template>
  <script>
  export default {
    name: 'HeaderComponent'
  }
  </script>
<style scoped>
/* Adjust navbar for RTL */
.navbar-nav {
  text-align: right;
}

/* Reverse the padding/margin for RTL layout */
.navbar-nav .nav-item {
  margin-left: 0;
  margin-right: 15px; /* Adjust as needed */
}

/* Align the logo to the right if needed */
.navbar-brand {
  margin-left: auto;
  margin-right: 0;
}
</style>
