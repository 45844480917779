<template>
    <section id="services">
      <div class="container">
        <h2>خدماتنا رائعة</h2> <!-- Translated title -->
        <div class="row">
          <div class="col-md-4 mt-15" v-for="(service, index) in services" :key="index">
            <div class="service">
              <img :src="service.icon" alt="Service Icon">
              <h3>{{ service.title }}</h3>
              <p>{{ service.description }}</p>
              <button class="btn order-btn">اطلب الخدمة</button> <!-- Translated button -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>

  <script>
  import inventory from '@/assets/img/1.png';
  import daily from '@/assets/img/3.png';
  import shopping from '@/assets/img/2.png';
  import bookkeeping from '@/assets/img/1.png'; // Example path for additional service icons
  import financialReports from '@/assets/img/2.png';
  import consulting from '@/assets/img/3.png';

  export default {
    name: 'ServicesComponent',
    data() {
      return {
        services: [
          {
            icon: daily,
            title: 'جميع القيود اليومية',
            description: 'إدارة وتسجيل القيود اليومية بدقة وفعالية'
          },
          {
            icon: shopping,
            title: 'تقارير المبيعات والمشتريات',
            description: 'تحليل شامل لتقارير المبيعات والمشتريات'
          },
          {
            icon: inventory,
            title: 'تقارير وأتمتة حركة المخزون',
            description: 'نظام دقيق لإدارة وتتبع المخزون'
          },
          {
            icon: bookkeeping,
            title: 'إدارة ومسك الدفاتر',
            description: 'تنظيم الدفاتر المحاسبية والحفاظ على السجلات المالية'
          },
          {
            icon: financialReports,
            title: 'إعداد التقارير المالية',
            description: 'إعداد وتحليل التقارير المالية الشهرية والسنوية'
          },
          {
            icon: consulting,
            title: 'الاستشارات المالية',
            description: 'تقديم الاستشارات المالية لتحسين الأداء المالي'
          },
          // Add more services here if needed
        ]
      };
    }
  };
  </script>

  <style scoped>
  #services {
    text-align: right; /* Align text to the right for RTL */
  }

  .service {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    text-align: center; /* Ensure text is centered within each service */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .service img {
    max-width: 100px;
    margin-bottom: 15px;
  }

  .service h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .service p {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .order-btn {
    background-color: #007bff; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .order-btn:hover {
    background-color: #0056b3; /* Darker button color on hover */
  }
  </style>
