<template>
    <section id="about">
      <div class="about-container">
        <div class="row" style="margin-right: 0px;"> <!-- Changed to margin-right for RTL -->
          <div class="col-md-8 bg-about">
            <div class="aboutustext" style="width: inherit; margin: auto; text-align: right;"> <!-- Text aligned to the right -->
              <h2>من نحن</h2> <!-- Translated title -->
              <p>نقدم حلول تقنية مالية مبتكرة مصممة لرواد الأعمال والمؤسسين في المملكة العربية السعودية. نساعدهم على إدارة تقاريرهم المالية ومؤشرات الأداء بفعالية، وإكمال جميع الإجراءات المالية والمحاسبية مع الهيئات التنظيمية بسهولة، وبناء المؤشرات المالية اللازمة لصناديق رأس المال الاستثماري والمسرعات.</p>
              <p>نسعى لتوفير الوقت والمال من خلال أتمتة العمليات المالية، ودعم اتخاذ قرارات أفضل استنادًا إلى بيانات دقيقة ومناسبة، وضمان الامتثال للأنظمة والقوانين السعودية، وفتح المجال للحصول على رأس المال من خلال بناء مؤشرات مالية قوية.</p>
              <p>نلتزم بتقديم حلول عالية الجودة تلبي احتياجاتك المحددة، مع فريق محترف من الأفراد ذوي الخبرة والكفاءة العالية، ودعم مستمر لضمان نجاحك.</p>
            </div>
          </div>
          <div class="col-md-4" style="margin-top: auto; margin-bottom: auto;"> <!-- Changed to margin-right for RTL -->
            <div class="about-images">
              <img src="../assets/img/image-three.png" alt="صورة من نحن 1"> <!-- Translated alt text -->
              <!-- <img src="../assets/img/image-two.png" alt="صورة من نحن 2"> Translated alt text -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>

  <script>
  export default {
    name: 'about-component'
  };
  </script>

  <style scoped>
  @media (max-width: 991px) {
    .bg-about {
      width: 100%;
      background: #233651 !important; /* Background color */
      padding: 20px;
    }
  }

  .aboutustext {
    margin-top: 20px !important;
  padding: 20px;
  word-wrap: break-word; /* Only break long words at appropriate places */
  overflow-wrap: break-word; /* Alternative for word-wrap */
  text-align: right; /* Align text to the right for RTL */
  }

  .about-container {
    width: 100% !important;
  }

  .bg-about {
    background: url('../assets/img/about-bg.png') no-repeat; /* Background image */
    background-position-x: left; /* Changed to left for RTL */
    background-position-y: top;
    background-size: cover;
    min-height: 553px;
    padding: 50px 0;
    color: #fff; /* Text color for contrast */
  }

  /* Apply the negative margin only on desktop screens */
  @media (min-width: 992px) {
    .bg-about {
      margin-right: -130px; /* Changed to margin-right for RTL */
    }
  }

  #about h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff; /* Ensure heading is visible */
  }

  #about p {
    text-align: right; /* Align text to the right for RTL */
    margin-bottom: 40px;
    color: #fff; /* Ensure text is visible */
  }

  .about-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: auto;
  margin-top: 50px; /* Margin top for all screens */
}

.about-images img {
  flex: 1;
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  position: relative;
}

/* Apply changes for mobile screens */
@media (max-width: 768px) {
  .about-images {
    margin-top: 50px; /* Add margin-top for mobile */
    flex-direction: column; /* Stack images vertically */
    align-items: center; /* Center images */
  }

  .about-images img {
    max-width: 90%; /* Make sure images fit the screen */
    margin-bottom: 20px; /* Add space between images */
  }

  .about-images img:first-child {
    transform: rotate(0); /* Remove rotation for better fit */
  }

  .about-images img:last-child {
    transform: rotate(0); /* Remove rotation for better fit */
  }
}
  </style>
