<template>
    <swiper-container
      dir="rtl"
      :slides-per-view="1"
      :space-between="spaceBetween"
      centered-slides="true"
      :pagination="{
        hideOnClick: true
      }"
      :breakpoints="{
        768: {
          slidesPerView: 1,
        },
      }"
      navigation
      @swiperprogress="onProgress"
      @swiperslidechange="onSlideChange"
    >
    <swiper-slide :style="backgroundImageStyle('5.jpg')">
        <div class="slide-content">
          <h2>نحو التحول الرقمي</h2>
          <p>نقدم حلول تقنية مبتكرة لقيادة التحول الرقمي لمؤسستك وتحقيق أهدافك.</p>
          <a class="cta-button" href="#services">اكتشف المزيد</a>
        </div>
      </swiper-slide>
      <swiper-slide :style="backgroundImageStyle('5.jpg')">
        <div class="slide-content">
          <h2>إدارة البيانات بكفاءة</h2>
          <p>نساعدك في إدارة بياناتك بكفاءة وسرعة لتحقيق قرارات أكثر فعالية.</p>
          <a class="cta-button" href="#services">ابدأ الآن</a>
        </div>
      </swiper-slide>
      <swiper-slide :style="backgroundImageStyle('5.jpg')">
        <div class="slide-content">
          <h2>حلول برمجية متكاملة</h2>
          <p>اكتشف كيف يمكن لحلولنا البرمجية أن تساعد في تحسين عملياتك اليومية.</p>
          <a class="cta-button" href="#services">استكشاف الآن</a>
        </div>
      </swiper-slide>
    </swiper-container>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'HeroSliderComponent',
  setup() {
    const spaceBetween = ref(5);

    const onProgress = (e) => {
      const [swiper, progress] = e.detail;
      console.log(progress, swiper);
    };

    const onSlideChange = () => {
      console.log('slide changed');
    };

    const backgroundImageStyle = (imgName) => {
  try {
    const resolvedUrl = require(`../assets/img/headerSlider/${imgName}`); // Dynamic image name
    return {
      backgroundImage: `url(${resolvedUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '400px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
    };
  } catch (error) {
    console.error(`Image not found: ${imgName}`, error);
    return {};
  }
};

    return {
      spaceBetween,
      onProgress,
      onSlideChange,
      backgroundImageStyle
    };
  }
});
</script>

<style scoped>
swiper-container {
  width: 76%;
  margin: 0 auto;
}

.swiper-slide {
  width: 100%; /* Ensures slide takes full width of swiper container */
  text-align: right; /* Align text to the right for RTL */
}

.slide-content {
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background for text readability */
  padding: 20px;
  border-radius: 8px;
  text-align: right; /* Right-align the content */
  color: #fff;
  position: relative;
  bottom: 20px; /* Adjust to fit within slide */
}

.slide-content h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.slide-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.cta-button {
  background-color: #e3c587; /* Vibrant button color */
  color: #233651;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;

}

.cta-button:hover {
  background-color: #c6a766; /* Darker button color on hover */
}

/* Add custom styles for Swiper navigation arrows */
.swiper-button-next,
.swiper-button-prev {
  color: #fff; /* Arrow color */
  background-color: rgba(0, 0, 0, 0.5); /* Arrow background color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  z-index: 10; /* Ensure buttons are above other content */
}

/* Reverse the arrow positions for RTL */
.swiper-button-next {
  left: 10px;
  right: auto;
}

.swiper-button-prev {
  right: 10px;
  left: auto;
}
</style>
