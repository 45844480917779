<template>
    <section id="partners">
      <div class="container">
        <h2>شركاؤنا الرائعون</h2>
        <swiper-container
          class="partners-swiper"
          :slides-per-view="5"
          :space-between="20"
          centered-slides="true"
          :breakpoints="breakpoints"
          navigation
          :autoplay="autoplay"
          ref="swiperContainer"
          dir="rtl"
        >
          <swiper-slide v-for="(partner, index) in partners" :key="index">
            <div class="brands_item">
              <img :src="partner.image" :alt="partner.name" />
            </div>
          </swiper-slide>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </swiper-container>
      </div>
    </section>
  </template>

  <script>
  import { defineComponent, ref, onMounted } from 'vue';
  import { register } from 'swiper/element/bundle';
  import amplify from '@/assets/img/partner/Asset 6.png';
import mraby from '@/assets/img/mraby.png';
import sheda from '@/assets/img/partner/Asset 2.png';
import ta5yar from '@/assets/img/partner/Asset 4.png';
import el3aren from '@/assets/img/partner/Asset 3.png';
import gulf from '@/assets/img/partner/Asset 5.png';
import nkwah from '@/assets/img/partner/Asset 8.png';
import shatla from '@/assets/img/partner/Asset 10.png';
import befine from '@/assets/img/partner/Asset 11.png';
import el3son from '@/assets/img/partner/Asset 12.png';
import asset13 from '@/assets/img/partner/Asset 13.png';
import bada from '@/assets/img/partner/Asset 14.png';
import icelab from '@/assets/img/partner/Asset 15.png';
import md5ol from '@/assets/img/partner/Asset 16.png';
import asset18 from '@/assets/img/partner/Asset 18.png';
import idimi from '@/assets/img/partner/Asset 19.png';
import h2h from '@/assets/img/partner/h2h.png';
import Wariq from '@/assets/img/partner/Wariq.jpg';
import good from '@/assets/img/partner/good.jpg';




  register();

  export default defineComponent({
    name: 'PartnersComponent',
    setup() {
      const swiperContainer = ref(null);
      const partners = [
      { name: 'Amplify', image: amplify },
      { name: 'Mraby', image: mraby },
      { name: 'Sheda', image: sheda },
      { name: 'h2h', image: h2h},
      { name: 'ta5yar', image: ta5yar},
      { name: '3aren', image: el3aren},
      { name: 'gulf', image: gulf},
      { name: 'nkwah', image: nkwah},
      { name: 'shatla', image: shatla},
      { name: 'befine', image: befine},
      { name: 'el3son', image: el3son},
      { name: 'asset13', image: asset13},
      { name: 'bada', image: bada},
      { name: 'icelab', image: icelab},
      { name: 'md5ol', image: md5ol},
      { name: 'asset18', image: asset18},
      { name: 'idimi', image: idimi},
      { name: 'Wariq', image: Wariq},
      { name: 'good', image: good},




    ];

      const breakpoints = {
        320: { slidesPerView: 1, spaceBetween: 10 },
        480: { slidesPerView: 2, spaceBetween: 20 },
        640: { slidesPerView: 3, spaceBetween: 20 },
        1024: { slidesPerView: 5, spaceBetween: 20 },
      };

      const autoplay = {
      delay: 2000, // 3 seconds between slides
      disableOnInteraction: false, // Do not stop autoplay after user interaction
    };

      onMounted(() => {
        const swiperEl = swiperContainer.value;

        const params = {
          injectStyles: [
            `
            :host(.partners-swiper) .swiper-button-next,
            :host(.partners-swiper) .swiper-button-prev {
              background-color: #ccc;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              color: gray;
            }
            `,
          ],
        };

        Object.assign(swiperEl, params);
        swiperEl.initialize();
      });

      return { swiperContainer, partners, breakpoints,autoplay  };
    },
  });
  </script>
<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
}

h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
  color: #333;
}

.partners-swiper {
  padding: 20px 0;
}

.brands_item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brands_item img {
  max-width: 100px;
  height: auto;
  transition: transform 0.3s;
}

.brands_item img:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .partners-swiper {
    padding: 10px 0;
  }

  .brands_item img {
    max-width: 80px;
  }
}

.swiper-button-next {
  right: auto;
  left: 10px;
}

.swiper-button-prev {
  left: auto;
  right: 10px;
}
</style>
